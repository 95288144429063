//for importing global styles
require("./src/styles/reset.scss")
require("./src/styles/global.scss")
require("./src/styles/style.scss")
const { mainJS } = require("./src/js/main")

exports.onRouteUpdate = () => {
  // console.log("here", );
  // mainJS();
}

exports.onInitialClientRender = () => {
  // console.log("now here", );
  // mainJS()
}
